var topBackgrounds = [
    __globalSiteConfig.cdnUri + '/assets/images/bg_home_top_male_fade.jpg',
    __globalSiteConfig.cdnUri + '/assets/images/bg_home_top_female_fade.jpg',
    __globalSiteConfig.cdnUri + '/assets/images/bg_home_top_female_2_fade.jpg'
];

function __getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

var topBackgroundIdx = __getRandomInt(0, topBackgrounds.length);

function __swapHomePageBackgrounds() {

    if (++topBackgroundIdx >= topBackgrounds.length) {
        topBackgroundIdx = 0;
    }

    var newImage = topBackgrounds[topBackgroundIdx];
    var topSection = $('#top');
    if (topSection.css("background-image") !== "url(\"" + newImage + "\")") {
        topSection.animate({opacity: 0}, 1000, function () {
            $('#top').css("background-image", "url('" + newImage + "')").animate({opacity: 1}, 1000);
        });
    }

}

setInterval(__swapHomePageBackgrounds, 8000);

$('.multiple-items').slick({
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 6000,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
    ]
});